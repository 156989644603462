function set(name, value, expire = 7 * 24 * 3600) {
  console.log(name, value)
  try {
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + expire * 1000);

    const encodedValue = encodeURIComponent(
      typeof value === 'string' ? value : JSON.stringify(value)
    );

    document.cookie = `${name}=${encodedValue};expires=${expiryDate.toUTCString()};path=/;SameSite=Strict${window.location.protocol === 'https:' ? ';Secure' : ''}`;
  } catch (error) {
    console.error('Error setting cookie:', error);
  }
}

function get(name) {
  try {
    const cookieStr = document.cookie
      .split(';')
      .find((cookie) => cookie.trim().startsWith(`${name}=`));

    if (!cookieStr) return null;

    const encodedValue = cookieStr.split('=')[1].trim();
    if (!encodedValue) return null;

    const decodedValue = decodeURIComponent(encodedValue);

    try {
      return JSON.parse(decodedValue);
    } catch {
      return decodedValue;
    }
  } catch (error) {
    console.error('Error getting cookie:', error);
    return null;
  }
}

function erase(name) {
  try {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;SameSite=Strict${window.location.protocol === 'https:' ? ';Secure' : ''}`;
  } catch (error) {
    console.error('Error erasing cookie:', error);
  }
}

export const cookie = {
  get,
  set,
  erase
};
