import { getMyPermissions } from 'src/AdminDashboard/service/dataService';
import { setPermissions } from 'src/store/slices/permissionsSlice';

export const createAuthMiddleware = () => (store) => (next) => async (action) => {
  const result = next(action);

  if (action.type === 'auth/setCredentials') {
    const user = action.payload?.user;
    if (
      user?.otherUserData?.user_type_code === 'ADMIN' ||
      user?.otherUserData?.user_type_code === 'INTERNAL_USER'
    ) {
      try {
        const permissions = await getMyPermissions();
        if (permissions) {
          store.dispatch(setPermissions(permissions));
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    }
  }

  return result;
};