import gonda10KWPlantImage from 'src/assets/images/projectsInstalled/gonda-10kw-project-image.jpg';
import kushinagar17KWPlantImage from 'src/assets/images/projectsInstalled/kushinager-17kw-project-image.png';
import ceratecAntara70KWPlantImage from 'src/assets/images/projectsInstalled/CeratecAntara-70KW-Yewalewadi-Pune-project-image.jpg';

export const socialLinks = {
  linkedin: 'https://in.linkedin.com/company/solfin-sustainable-financing',
  twitter: 'https://twitter.com/solfin_india',
  facebook:
    'https://www.facebook.com/profile.php?id=61558828528270&mibextid=ZbWKwL'
};

export const epcRegistrationTypes = [
  { id: 1, name: 'Sole Prop', label: 'Sole Prop' },
  { id: 2, name: 'Other', label: 'Other' }
];

export const documentsCategory = {
  COMPANY_DOCUMENT: 'Company Document',
  SIGNATORY_DOCUMENT: 'Signatory Document'
};

export const distributorTabs = [
  { id: 1, label: 'New Tasks' },
  { id: 2, label: 'In Progress Tasks' }
];

export const ourProjectList = [
  {
    id: 1,
    location: 'Gonda, Uttar Pradesh',
    units: '10KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: gonda10KWPlantImage
  },
  {
    id: 2,
    location: 'kushinagar - Sukrauli, Uttar Pradesh',
    units: '17KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: kushinagar17KWPlantImage
  },
  {
    id: 3,
    location: 'Ceratec Antara, Pune, Maharashtra',
    units: '70KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: ceratecAntara70KWPlantImage
  }
];

export const howWeWorkSteps = [
  {
    title: 'System Design',
    text: 'EPC would conduct a site visit and design a system',
    icon: '/images/solar-panel.svg',
    alt: 'System Design'
  },
  {
    title: 'Consultation',
    text: 'Discuss with EPC, the project in detail on Kwh requirement, Net Metering, Storage etc.',
    icon: '/images/consultation.svg',
    alt: 'Consultation'
  },
  {
    title: 'Flexible Financing',
    text: 'Choose best finance options basis interest rate, tenure, via state of art Digital loan processing system',
    icon: '/images/payment-plan.svg',
    alt: 'Payment Plan'
  },
  {
    title: 'Installation',
    text: 'Customer can get the project installed after closure of EPC and Financing contracts.',
    icon: '/images/installation.svg',
    alt: 'Installation'
  },
  {
    title: 'Monitoring & Maintenance',
    text: 'Low cost maintenance through EPC and special maintenance partners',
    icon: '/images/monitoring-maintance.svg',
    alt: 'Monitoring & Maintenance'
  },
  {
    title: 'Plant Optimization & Expansion',
    text: 'Storage Options and utilization options',
    icon: '/images/battery-optimization.svg',
    alt: 'Battery Optimization'
  }
];

export const loanFinancingType = {
  RESIDENTIAL: 'Residential Lead',
  COMMERCIAL_AND_INDUSTRIAL: 'C & I Lead',
  PLL: 'Performance Linked Loan',
  OTHER: 'Housing Societies / Other'
};

export const loanFinancingCategories = [
  {
    id: 'RESIDENTIAL',
    imageUrl:
      'https://png.pngtree.com/background/20231030/original/pngtree-contract-form-and-approved-home-loan-stunning-3d-illustration-picture-image_5808578.jpg',
    imageTitle: 'Residential Lead',
    isActive: true,
    title: 'Residential Lead'
  },
  {
    id: 'COMMERCIAL_AND_INDUSTRIAL',
    imageUrl:
      'https://img.freepik.com/premium-photo/3d-flat-icon-business-financial-concept-bankers-discussing-finance-services-loan-option_980716-68802.jpg',
    imageTitle: 'C & I',
    isActive: true,
    title: 'C & I'
  },
  {
    id: 'PLL',
    imageUrl:
      'https://png.pngtree.com/background/20231030/original/pngtree-contract-form-and-approved-home-loan-stunning-3d-illustration-picture-image_5808578.jpg',
    imageTitle: 'Performance Linked Loan',
    isActive: false,
    title: 'Performance Linked Loan'
  },
  {
    id: 'OTHER',
    imageUrl:
      'https://static.vecteezy.com/system/resources/previews/021/596/685/non_2x/3d-illustration-loan-in-credit-and-loan-set-free-png.png',
    imageTitle: 'Other',
    isActive: true,
    title: 'Housing Societies / Other'
  }
];

export const solfinWhatsSupportNumber = '+919599446877';
export const groupLink = 'https://chat.whatsapp.com/IYfGlyGK3qg4o3cATEUjCT';

export const whatsappSupportDefaultMessage =
  'Hello, \nI need your assistance regarding the EPC.';
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 0;

export const masterPermissionsSet = [
  'LEADS_UPDATE',
  'LEADS_CREATE',
  'LEADS_VIEW',
  'LEADS_DELETE',
  'APPLICATION_UPDATE',
  'APPLICATION_DELETE',
  'APPLICATION_VIEW',
  'CUSTOMERS_VIEW',
  'CUSTOMERS_DELETE',
  'CUSTOMERS_UPDATE',
  'CUSTOMERS_CREATE',
  'EPC_VIEW',
  'EPC_UPDATE',
  'EPC_CREATE',
  'EPC_DELETE',
  'DISTRIBUTOR_VIEW',
  'DISTRIBUTOR_CREATE',
  'DISTRIBUTOR_DELETE',
  'DISTRIBUTOR_UPDATE',
  'INVOICES_VIEW',
  'INVOICES_CREATE',
  'INVOICES_DELETE',
  'INVOICES_UPDATE',
  'DSA_VIEW',
  'DSA_CREATE',
  'DSA_DELETE',
  'DSA_UPDATE',
  'PROJECTS_VIEW',
  'PROJECTS_DELETE',
  'PROJECTS_CREATE',
  'PROJECTS_UPDATE',
  'UPDATE_KYC'
];

export const adminPermissions = {
  LEADS_UPDATE: true,
  LEADS_CREATE: true,
  APPLICATION_VIEW: true,
  CUSTOMERS_UPDATE: true,
  PROJECTS_CREATE: true,
  INVOICES_VIEW: true,
  EPC_VIEW: true,
  DSA_CREATE: true,
  UPDATE_KYC: true
};

export const INITIAL_PAGE_NUMBER = 0;
export const INITIAL_PAGE_SIZE = 10;

export const indianStateColors = {
  AndhraPradesh: 'linear-gradient(120deg, #FF7E5F, #FEB47B)',
  ArunachalPradesh: 'linear-gradient(120deg, #4CA1AF, #C4E0E5)',
  Assam: 'linear-gradient(120deg, #FF9966, #FF5E62)',
  Bihar: 'linear-gradient(120deg, #56CCF2, #2F80ED)',
  Chhattisgarh: 'linear-gradient(120deg, #FF9A8B, #FF6A88)',
  Goa: 'linear-gradient(120deg, #36D1DC, #5B86E5)',
  Gujarat: 'linear-gradient(120deg, #8E2DE2, #4A00E0)',
  Haryana: 'linear-gradient(120deg, #43CEA2, #185A9D)',
  HimachalPradesh: 'linear-gradient(120deg, #FFB75E, #ED8F03)',
  Jharkhand: 'linear-gradient(120deg, #00C6FF, #0072FF)',
  Karnataka: 'linear-gradient(120deg, #DCE35B, #45B649)',
  Kerala: 'linear-gradient(120deg, #FF5F6D, #FFC371)',
  MadhyaPradesh: 'linear-gradient(120deg, #12C2E9, #C471ED, #F64F59)',
  Maharashtra: 'linear-gradient(120deg, #FF416C, #FF4B2B)',
  Manipur: 'linear-gradient(120deg, #1FA2FF, #12D8FA, #A6FFCB)',
  Meghalaya: 'linear-gradient(120deg, #00B4DB, #0083B0)',
  Mizoram: 'linear-gradient(120deg, #43C6AC, #191654)',
  Nagaland: 'linear-gradient(120deg, #7F00FF, #E100FF)',
  Odisha: 'linear-gradient(120deg, #D9A7C7, #FFFCDC)',
  Punjab: 'linear-gradient(120deg, #F7971E, #FFD200)',
  Rajasthan: 'linear-gradient(120deg, #FDFC47, #24FE41)',
  Sikkim: 'linear-gradient(120deg, #F4D03F, #16A085)',
  TamilNadu: 'linear-gradient(120deg, #1D976C, #93F9B9)',
  Telangana: 'linear-gradient(120deg, #FF512F, #DD2476)',
  Tripura: 'linear-gradient(120deg, #FF758C, #FF7EB3)',
  UttarPradesh: 'linear-gradient(120deg, #F3E7E9, #E3EEFF)',
  Uttarakhand: 'linear-gradient(120deg, #52ACFF, #FFE32C)',
  WestBengal: 'linear-gradient(120deg, #FF9A8B, #FF6A88, #FF99AC)',

  // Union Territories
  AndamanAndNicobarIslands: 'linear-gradient(120deg, #FBAB7E, #F7CE68)',
  Chandigarh: 'linear-gradient(120deg, #4AC29A, #BDFFF3)',
  DadraAndNagarHaveliAndDamanAndDiu:
    'linear-gradient(120deg, #FA709A, #FEE140)',
  Delhi: 'linear-gradient(120deg, #30E8BF, #FF8235)',
  JammuAndKashmir: 'linear-gradient(120deg, #74EBD5, #9FACE6)',
  Ladakh: 'linear-gradient(120deg, #FFDEE9, #B5FFFC)',
  Lakshadweep: 'linear-gradient(120deg, #43E97B, #38F9D7)',
  Puducherry: 'linear-gradient(120deg, #36D1DC, #5B86E5)'
};

export const paymentStatusOptions = [
  {
    enumName: 'ON_TIME',
    status: 'On time'
  },
  {
    enumName: 'NPA',
    status: 'Delayed'
  },
  {
    enumName: 'OVER_DUE',
    status: 'Over due'
  }
];

export const LOAN_DOCUMENT_TYPES = {
  WELCOME_LETTER: {
    key: 'WELCOME_LETTER',
    label: 'Welcome Letter',
    isMonthly: false,
    required: true
  },
  SANCTION_LETTER_SIGNED: {
    key: 'SANCTION_LETTER_SIGNED',
    label: 'Sanction Letter (Signed)',
    isMonthly: false,
    required: true
  },
  LOAN_AGREEMENT_SIGNED: {
    key: 'LOAN_AGREEMENT_SIGNED',
    label: 'Loan Agreement (Signed)',
    isMonthly: false,
    required: true
  },
  LOAN_NOC: {
    key: 'LOAN_NOC',
    label: 'Loan NOC',
    isMonthly: false,
    required: false
  },
  MONTHLY_STATEMENT: {
    key: 'MONTHLY_STATEMENT',
    label: 'Monthly Statement',
    isMonthly: true,
    required: false
  }
};

export const DYNAMIC_FORM_LABELS = {
  borrowerName: 'Name of Borrower',
  borrowerType: 'Form of Borrower',
  businessType: 'Nature of Business',
  officeAddress: 'Registered Office Address',
  dateOfIncorporation: 'Date of Incorporation',
  cin: 'CIN/Registration Certificate',
  pan: 'PAN',
  gst: 'GST',
  aadhar: 'Aadhar',
  udyam: 'Udhyam Card (In case of business)',
  email: 'Email',
  phone: 'Phone No',
  voterId: 'Voter ID (Optional)',
  passportNo: 'Passport No (Optional)',
  signatoryName:
    'Name of Authorised Signatory/Director/Partner including designation',
  signatoryName1:
    'Name of Authorised Signatory/Director/Partner including designation',
  signatoryPan: 'PAN of Auth Sig/Director/Partner 1',
  signatoryAadhaar: 'Aadhar Auth Sig/Director/Partner 1',
  signatoryAddress: 'Address of Auth Sig/Director/Partner 1',
  signatoryEmail: 'Email of Auth Sig/Director/Partner 1',
  signatoryPhone: 'Phone No of Auth Sig/Director/Partner 1',
  signatoryPan1: 'PAN of Auth Sig/Director/Partner 2',
  signatoryAadhaar1: 'Aadhar Auth Sig/Director/Partner 2',
  signatoryAddress1: 'Address of Auth Sig/Director/Partner 2',
  signatoryEmail1: 'Email of Auth Sig/Director/Partner 2',
  signatoryPhone1: 'Phone No of Auth Sig/Director/Partner 2',
  loanRequiredAmountCapitalFinance:
    'Loan Requirement Amount (Working Capital Finance)',
  loanTenureMonthsCapitalFinance:
    'Loan Requirement Tenure (Months) (Working Capital Finance)',
  detailedReasonCapitalFinance: 'Detailed Reason (Working Capital Finance)',
  loanRequiredAmountPI: 'Loan Requirement Amount (PO/Proforma Invoice Finance)',
  loanRequiredTenurePI:
    'Loan Requirement Tenure (Months) (PO/Proforma Invoice Finance)',
  invoiceNumberPI: 'PO/Proforma Invoice No',
  invoiceAmountPI: 'PO/Proforma Invoice Amount',
  supplier: 'Supplier',
  bankAccountOfSupplier: 'Disbursal Bank Account of Supplier',
  phoneNumberOfSupplier: 'Phone No of Supplier',
  emailOfSupplier: 'Email of Supplier',
  solarRoofTopLoanAddressOfInstallation:
    'Address of Installation (Solar Roof Top Loan)',
  solarRoofTopLoanInstallationPremises:
    'Installation premises (Commercial/Residential/Industrial)',
  solarRoofTopLoanOwnershipOfPremises:
    'Ownership of premises (Solar Roof Top Loan)',
  nameOfMeterOwner: 'Name of Meter Owner',
  averageElectBillAmountPms: 'Average Elect Bill Amount p.m.',
  averageUnitsConsumedPms: 'Average Units Consumed p.m.',
  averageElectricityRate: 'Average Electricity Rate',
  averageUnitsEnvisagedToBeReplacedThroughSolarRooftopProject:
    'Average units envisaged to be replaced through Solar Rooftop project',
  averageUnitsEnvisagedToBeProducedThroughSolarRooftopProject:
    'Average units envisaged to be produced through Solar Rooftop project',
  nameOfManufacturerEpcVendor: 'Name of Manufacturer/EPC/Vendor',
  assetMake: 'Asset Make',
  resourceAssessmentReport: 'Resource Assessment Report',
  netMeteringApprovalFromDiscom: 'Net-metering approval from DISCOM',
  dealerQuotationNo: 'Dealer Quotation No',
  dealerBankAccount: 'Dealer Bank Account',
  proformaInvoiceNo: 'Proforma Invoice No',
  proformaInvoiceAmountPanels: 'Proforma Invoice Amount Panels',
  proformaInvoiceAmountInverter: 'Proforma Invoice Amount Inverter',
  installationExpenses: 'Installation Expenses',
  totalProjectCostA: 'Total Project Cost (A)',
  nonDealerExpenses: 'Non-Dealer Expenses (Specify)',
  totalProjectCostB: 'Total Project Cost (B)',
  loanAmount: 'Loan Amount',
  loanRequirementTenureMonths: 'Loan Requirement Tenure (Months)',
  dealerPhoneNo: 'Dealer Phone No.',
  dealerEmailId: 'Dealer Email ID',
  incomeTaxReturnsForTheLast2Years: 'Income Tax Returns for the last 2 Years',
  comfortLetterFromWaareeSolarManufacturer:
    'Comfort Letter from Waaree/Solar Manufacturer',
  applicationPlace: 'Application Place',
  nameOfGuarantorCoborrower: 'Name of Guarantor/Coborrower',
  fathersNameOfGuarantorCoborrower: 'Fathers Name of Guarantor/Coborrower',
  addressOfGuarantorCoborrower: 'Address of Guarantor/Coborrower',
  panNumberOfGuarantorCoborrower: 'PAN Number of Guarantor/Coborrower',
  aadharOfGuarantorCoborrower: 'Aadhar of Guarantor/Coborrower',
  solarRooftopCommercialInstitutional:
    'Solar Rooftop Commercial & Institutional',
  hypothecationOfAsset: 'Hypothecation of Asset',
  personalGuaranteeOfPromoterDirectors:
    'Personal Guarantee of Promoter Directors',
  guaranteeFromEpcContractor: 'Guarantee from EPC Contractor',
  dealerFinance: 'Dealer Finance',
  hypothecationOfStock: 'Hypothecation of Stock'
};

export const mockAPIResponse = {
  date: '2025-01-20',
  borrowerName: 'SATISH Doe',
  borrowerType: 'Individual',
  businessType: 'Sole Proprietorship',
  officeAddress: '123 Business St, City, Country',
  dateOfIncorporation: '2015-05-01',
  cin: 'U12345XYZ123456',
  pan: 'ABCDE1234F',
  gst: '123456789012',
  aadhar: '1234 5678 9101',
  udyam: 'Udyam123456789',
  email: 'john.doe@example.com',
  phone: '+1234567890',
  voterId: 'VOTER1234567',
  passportNo: 'P12345678',
  signatoryName: 'SATISH Smith',
  loanRequiredAmountCapitalFinance: '1000000',
  loanTenureMonthsCapitalFinance: '36',
  detailedReasonCapitalFinance: 'Expansion of business operations.',
  loanRequiredAmountPI: '500000',
  loanRequiredTenurePI: '24',
  invoiceNumberPI: 'INV12345678',
  invoiceAmountPI: '400000',
  supplier: 'ABC Supplies Ltd.',
  bankAccountOfSupplier: '1234567890',
  phoneNumberOfSupplier: '+987654321',
  emailOfSupplier: 'supplier@abc.com',
  solarRoofTopLoanAddressOfInstallation: '789 Solar Rd, Rooftop, City',
  solarRoofTopLoanInstallationPremises: 'Commercial Building',
  solarRoofTopLoanOwnershipOfPremises: 'Owned',
  nameOfMeterOwner: 'XYZ Corporation',
  averageElectBillAmountPms: '15000',
  averageUnitsConsumedPms: '1200',
  averageElectricityRate: '12.5',
  averageUnitsEnvisagedToBeReplacedThroughSolarRooftopProject: '1000',
  averageUnitsEnvisagedToBeProducedThroughSolarRooftopProject: '1200',
  nameOfManufacturerEpcVendor: 'SolarTech Pvt Ltd.',
  assetMake: 'SolarPanel 2025',
  resourceAssessmentReport: 'Available',
  netMeteringApprovalFromDiscom: 'Yes',
  dealerQuotationNo: 'DQ123456',
  dealerBankAccount: '987654321',
  proformaInvoiceNo: 'PF123456',
  proformaInvoiceAmountPanels: '250000',
  proformaInvoiceAmountInverter: '150000',
  installationExpenses: '200000',
  totalProjectCostA: '600000',
  loanAmount: '500000',
  loanRequirementTenureMonths: '24',
  dealerPhoneNo: '+1239876543',
  dealerEmailId: 'dealer@xyz.com',
  incomeTaxReturnsForTheLast2Years: 'Filed and Available',
  comfortLetterFromWaareeSolarManufacturer: 'Available',
  applicationPlace: ''
};

export const FIELD_CONFIG = {
  // section-A
  date: {
    label: 'Date',
    type: 'date',
    defaultValue: new Date().toISOString().split('T')[0],
    validation: { required: true }
  },
  borrowerName: {
    label: 'Name of Borrower',
    type: 'text',
    validation: { required: true }
  },
  borrowerType: {
    label: 'Form of Borrower',
    type: 'text',
    validation: { required: true }
  },
  businessType: {
    label: 'Nature of Business',
    type: 'text',
    validation: { required: true }
  },
  officeAddress: {
    label: 'Registered Office Address',
    type: 'textarea',
    validation: { required: true }
  },
  dateOfIncorporation: {
    label: 'Date of Incorporation',
    type: 'date',
    validation: { required: true }
  },

  // section-B
  cin: {
    label: 'CIN/Registration Certificate',
    type: 'text'
  },
  pan: {
    label: 'PAN',
    type: 'text',
    validation: { required: true }
  },
  gst: {
    label: 'GST',
    type: 'text',
    validation: { required: true }
  },
  aadhar: {
    label: 'Aadhar',
    type: 'text',
    validation: { required: true }
  },
  udyam: {
    label: 'Udhyam Card',
    type: 'text'
  },
  email: {
    label: 'Email',
    type: 'text',
    validation: { email: true, required: true }
  },
  phone: {
    label: 'Phone No',
    type: 'text',
    validation: { phone: true, required: true }
  },
  voterId: {
    label: 'Voter ID',
    type: 'text'
  },
  passportNo: {
    label: 'Passport No',
    type: 'text'
  },

  // Section C : In case of Company/Firm/LLP/Society Additional Details
  signatoryName: {
    label: 'Name of Authorized Signatory',
    type: 'text',
    validation: { required: true }
  },
  signatoryName1: {
    label: 'Name of Authorized Signatory 2',
    type: 'text',
    validation: { required: true }
  },
  signatoryPan: {
    label: 'PAN of Authorized Signatory 1',
    validation: { required: true },
    type: 'text'
  },
  signatoryAadhaar: {
    label: 'Aadhar of Authorized Signatory 1',
    validation: { required: true },
    type: 'number'
  },
  signatoryAddress: {
    label: 'Address of Authorized Signatory 1',
    validation: { required: true },
    type: 'textarea'
  },
  signatoryEmail: {
    label: 'Email of Authorized Signatory 1',
    type: 'text',
    validation: { email: true }
  },
  signatoryPhone: {
    label: 'Phone No of Authorized Signatory 1',
    type: 'text',
    validation: { phone: true, required: true }
  },
  signatoryPan1: {
    label: 'PAN of Authorized Signatory 2',
    type: 'text'
  },
  signatoryAadhaar1: {
    label: 'Aadhar of Authorized Signatory 2',
    type: 'number'
  },
  signatoryAddress1: {
    label: 'Address of Authorized Signatory 2',
    validation: { required: true },
    type: 'textarea'
  },
  signatoryEmail1: {
    label: 'Email of Authorized Signatory 2',
    type: 'text',
    validation: { email: true, required: true }
  },
  signatoryPhone1: {
    label: 'Phone No of Authorized Signatory 2',
    validation: { phone: true, required: true },
    type: 'text'
  },

  // Section D : Loan Requirement/Working Capital Finance
  loanRequiredAmountCapitalFinance: {
    label: 'Loan Requirement Amount (Capital Finance)',
    type: 'number'
  },
  loanTenureMonthsCapitalFinance: {
    label: 'Loan Tenure (Months)',
    type: 'number'
  },
  detailedReasonCapitalFinance: {
    label: 'Detailed Reason for Capital Finance Loan',
    type: 'text'
  },
  // PO/Proforma Invoice Finance
  loanRequiredAmountPI: {
    label: 'Loan Requirement Amount (Proforma Invoice Finance)',
    type: 'number'
  },
  loanRequiredTenurePI: {
    label: 'Loan Tenure (Months) for Proforma Invoice Finance',
    type: 'number'
  },
  invoiceNumberPI: {
    label: 'Invoice No (Proforma Invoice Finance)',
    type: 'text'
  },
  invoiceAmountPI: {
    label: 'Invoice Amount (Proforma Invoice Finance)',
    type: 'number'
  },
  supplier: {
    label: 'Supplier',
    type: 'text'
  },
  bankAccountOfSupplier: {
    label: 'Bank Account of Supplier',
    type: 'text'
  },
  phoneNumberOfSupplier: {
    label: 'Phone No of Supplier',
    type: 'text'
  },
  emailOfSupplier: {
    label: 'Email of Supplier',
    type: 'text',
    validation: { email: true }
  },

  // Solar Roof Top Loan
  solarRoofTopLoanAddressOfInstallation: {
    label: 'Solar Roof Top Loan - Address of Installation',
    type: 'text'
  },
  solarRoofTopLoanInstallationPremises: {
    label: 'Solar Roof Top Loan - Installation Premises',
    type: 'text'
  },
  solarRoofTopLoanOwnershipOfPremises: {
    label: 'Solar Roof Top Loan - Ownership of Premises',
    type: 'text'
  },
  nameOfMeterOwner: {
    label: 'Name of Meter Owner',
    type: 'text'
  },
  averageElectBillAmountPms: {
    label: 'Average Electricity Bill Amount (PMS)',
    type: 'number'
  },
  averageUnitsConsumedPms: {
    label: 'Average Units Consumed (PMS)',
    type: 'number'
  },
  averageElectricityRate: {
    label: 'Average Electricity Rate',
    type: 'number'
  },
  averageUnitsEnvisagedToBeReplacedThroughSolarRooftopProject: {
    label: 'Average Units to Be Replaced Through Solar Rooftop Project',
    type: 'number'
  },
  averageUnitsEnvisagedToBeProducedThroughSolarRooftopProject: {
    label: 'Average Units to Be Produced Through Solar Rooftop Project',
    type: 'number'
  },
  nameOfManufacturerEpcVendor: {
    label: 'Name of Manufacturer/EPC/Vendor',
    type: 'text'
  },
  assetMake: {
    label: 'Asset Make',
    type: 'text'
  },
  resourceAssessmentReport: {
    label: 'Resource Assessment Report',
    type: 'text'
  },
  netMeteringApprovalFromDiscom: {
    label: 'Net Metering Approval from DISCOM',
    type: 'boolean'
  },
  dealerQuotationNo: {
    label: 'Dealer Quotation No',
    type: 'text'
  },
  dealerBankAccount: {
    label: 'Dealer Bank Account',
    type: 'text'
  },
  proformaInvoiceNo: {
    label: 'Proforma Invoice No',
    type: 'text'
  },
  proformaInvoiceAmountPanels: {
    label: 'Proforma Invoice Amount (Panels)',
    type: 'number'
  },
  proformaInvoiceAmountInverter: {
    label: 'Proforma Invoice Amount (Inverter)',
    type: 'number'
  },
  installationExpenses: {
    label: 'Installation Expenses',
    type: 'number'
  },
  totalProjectCostA: {
    label: 'Total Project Cost A',
    type: 'number'
  },
  loanAmount: {
    label: 'Loan Amount',
    type: 'number'
  },
  loanRequirementTenureMonths: {
    label: 'Loan Requirement Tenure (Months)',
    type: 'number'
  },
  dealerPhoneNo: {
    label: 'Dealer Phone No',
    type: 'text'
  },
  dealerEmailId: {
    label: 'Dealer Email ID',
    type: 'text',
    validation: { email: true }
  },

  // Section E : List of Documents/Others
  incomeTaxReturnsForTheLast2Years: {
    label: 'Income Tax Returns for the Last 2 Years',
    type: 'text'
  },
  comfortLetterFromWaareeSolarManufacturer: {
    label: 'Comfort Letter from Waaree Solar Manufacturer',
    type: 'text'
  },
  applicationPlace: {
    label: 'Place of Application',
    type: 'text'
  }
};

export const DETAILED_FIELD_CONFIG = {
  // Section A: Borrower Information
  sectionA: {
    title: 'Section A: Borrower Information',
    details:
      'Please provide details of the borrower, including business and registration information.',
    fields: {
      date: {
        label: 'Date',
        type: 'date',
        defaultValue: new Date().toISOString().split('T')[0],
        validation: { required: true }
      },
      borrowerName: {
        label: 'Name of Borrower',
        type: 'text',
        validation: { required: true }
      },
      borrowerType: {
        label: 'Form of Borrower',
        type: 'text',
        validation: { required: true }
      },
      businessType: {
        label: 'Nature of Business',
        type: 'text',
        validation: { required: true }
      },
      officeAddress: {
        label: 'Registered Office Address',
        type: 'textarea',
        validation: { required: true }
      },
      dateOfIncorporation: {
        label: 'Date of Incorporation',
        type: 'date',
        validation: { required: true }
      }
    }
  },

  // Section B: Registration Details
  sectionB: {
    title: 'Section B: Registration Details',
    details:
      'Provide the registration and identification details for the borrower.',
    fields: {
      cin: {
        label: 'CIN/Registration Certificate',
        type: 'text'
      },
      pan: {
        label: 'PAN',
        type: 'text',
        validation: { required: true }
      },
      gst: {
        label: 'GST',
        type: 'text',
        validation: { required: true }
      },
      aadhar: {
        label: 'Aadhar',
        type: 'text',
        validation: { required: true }
      },
      udyam: {
        label: 'Udhyam Card',
        type: 'text'
      },
      email: {
        label: 'Email',
        type: 'text',
        validation: { email: true, required: true }
      },
      phone: {
        label: 'Phone No',
        type: 'text',
        validation: { phone: true, required: true }
      },
      voterId: {
        label: 'Voter ID',
        type: 'text'
      },
      passportNo: {
        label: 'Passport No',
        type: 'text'
      }
    }
  },

  // Section C: Authorized Signatory Details
  sectionC: {
    title: 'Section C: Authorized Signatory Details',
    details:
      'Provide details of the authorized signatories, including contact and identification information.',
    fields: {
      signatoryName: {
        label: 'Name of Authorized Signatory',
        type: 'text',
        validation: { required: true }
      },
      signatoryName1: {
        label: 'Name of Authorized Signatory 2',
        type: 'text',
        validation: { required: true }
      },
      signatoryPan: {
        label: 'PAN of Authorized Signatory 1',
        validation: { required: true },
        type: 'text'
      },
      signatoryAadhaar: {
        label: 'Aadhar of Authorized Signatory 1',
        validation: { required: true },
        type: 'number'
      },
      signatoryAddress: {
        label: 'Address of Authorized Signatory 1',
        validation: { required: true },
        type: 'textarea'
      },
      signatoryEmail: {
        label: 'Email of Authorized Signatory 1',
        type: 'text',
        validation: { email: true }
      },
      signatoryPhone: {
        label: 'Phone No of Authorized Signatory 1',
        type: 'text',
        validation: { phone: true, required: true }
      },
      signatoryPan1: {
        label: 'PAN of Authorized Signatory 2',
        type: 'text'
      },
      signatoryAadhaar1: {
        label: 'Aadhar of Authorized Signatory 2',
        type: 'number'
      },
      signatoryAddress1: {
        label: 'Address of Authorized Signatory 2',
        validation: { required: true },
        type: 'textarea'
      },
      signatoryEmail1: {
        label: 'Email of Authorized Signatory 2',
        type: 'text',
        validation: { email: true, required: true }
      },
      signatoryPhone1: {
        label: 'Phone No of Authorized Signatory 2',
        validation: { phone: true, required: true },
        type: 'text'
      }
    }
  },

  // Section D: Loan Requirement Details
  sectionD: {
    title: 'Section D: Loan Requirements',
    details:
      'Provide details for the loan requirements including capital finance, proforma invoice, and solar rooftop loans.',
    fields: {
      loanRequiredAmountCapitalFinance: {
        label: 'Loan Requirement Amount (Capital Finance)',
        type: 'number'
      },
      loanTenureMonthsCapitalFinance: {
        label: 'Loan Tenure (Months)',
        type: 'number'
      },
      detailedReasonCapitalFinance: {
        label: 'Detailed Reason for Capital Finance Loan',
        type: 'text'
      },
      // PO/Proforma Invoice Finance
      loanRequiredAmountPI: {
        label: 'Loan Requirement Amount (Proforma Invoice Finance)',
        type: 'number'
      },
      loanRequiredTenurePI: {
        label: 'Loan Tenure (Months) for Proforma Invoice Finance',
        type: 'number'
      },
      invoiceNumberPI: {
        label: 'Invoice No (Proforma Invoice Finance)',
        type: 'text'
      },
      invoiceAmountPI: {
        label: 'Invoice Amount (Proforma Invoice Finance)',
        type: 'number'
      },
      supplier: {
        label: 'Supplier',
        type: 'text'
      },
      bankAccountOfSupplier: {
        label: 'Bank Account of Supplier',
        type: 'text'
      },
      phoneNumberOfSupplier: {
        label: 'Phone No of Supplier',
        type: 'text'
      },
      emailOfSupplier: {
        label: 'Email of Supplier',
        type: 'text',
        validation: { email: true }
      },
      // Solar Roof Top Loan
      solarRoofTopLoanAddressOfInstallation: {
        label: 'Solar Roof Top Loan - Address of Installation',
        type: 'text'
      },
      solarRoofTopLoanInstallationPremises: {
        label: 'Solar Roof Top Loan - Installation Premises',
        type: 'text'
      },
      solarRoofTopLoanOwnershipOfPremises: {
        label: 'Solar Roof Top Loan - Ownership of Premises',
        type: 'text'
      },
      nameOfMeterOwner: {
        label: 'Name of Meter Owner',
        type: 'text'
      },
      averageElectBillAmountPms: {
        label: 'Average Electricity Bill Amount (PMS)',
        type: 'number'
      },
      averageUnitsConsumedPms: {
        label: 'Average Units Consumed (PMS)',
        type: 'number'
      },
      averageElectricityRate: {
        label: 'Average Electricity Rate',
        type: 'number'
      },
      averageUnitsEnvisagedToBeReplacedThroughSolarRooftopProject: {
        label: 'Average Units to Be Replaced Through Solar Rooftop Project',
        type: 'number'
      },
      averageUnitsEnvisagedToBeProducedThroughSolarRooftopProject: {
        label: 'Average Units to Be Produced Through Solar Rooftop Project',
        type: 'number'
      },
      nameOfManufacturerEpcVendor: {
        label: 'Name of Manufacturer/EPC/Vendor',
        type: 'text'
      },
      assetMake: {
        label: 'Asset Make',
        type: 'text'
      },
      resourceAssessmentReport: {
        label: 'Resource Assessment Report',
        type: 'text'
      },
      netMeteringApprovalFromDiscom: {
        label: 'Net Metering Approval from DISCOM',
        type: 'boolean'
      },
      dealerQuotationNo: {
        label: 'Dealer Quotation No',
        type: 'text'
      },
      dealerBankAccount: {
        label: 'Dealer Bank Account',
        type: 'text'
      },
      proformaInvoiceNo: {
        label: 'Proforma Invoice No',
        type: 'text'
      },
      proformaInvoiceAmountPanels: {
        label: 'Proforma Invoice Amount (Panels)',
        type: 'number'
      },
      proformaInvoiceAmountInverter: {
        label: 'Proforma Invoice Amount (Inverter)',
        type: 'number'
      },
      installationExpenses: {
        label: 'Installation Expenses',
        type: 'number'
      },
      totalProjectCostA: {
        label: 'Total Project Cost A',
        type: 'number'
      },
      loanAmount: {
        label: 'Loan Amount',
        type: 'number'
      },
      loanRequirementTenureMonths: {
        label: 'Loan Requirement Tenure (Months)',
        type: 'number'
      },
      dealerPhoneNo: {
        label: 'Dealer Phone No',
        type: 'text'
      },
      dealerEmailId: {
        label: 'Dealer Email ID',
        type: 'text',
        validation: { email: true }
      }
    }
  },

  // Section E: Document Details
  sectionE: {
    title: 'Section E: Document Details',
    details:
      'Upload or provide information about the required documents and any other information.',
    fields: {
      incomeTaxReturnsForTheLast2Years: {
        label: 'Income Tax Returns for the Last 2 Years',
        type: 'text'
      },
      comfortLetterFromWaareeSolarManufacturer: {
        label: 'Comfort Letter from Waaree Solar Manufacturer',
        type: 'text'
      },
      applicationPlace: {
        label: 'Place of Application',
        type: 'text'
      }
    }
  }
};
