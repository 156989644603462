export const UI_ROUTES = {
  dashboard: '/',
  login: 'login',
  signup: 'Register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password/:token',
  finance: 'finance',
  registerCustomer: 'RegisterCustomer',
  premiumPartners: 'PremiumPartners',
  cookiePolicy: 'CookiePolicy',
  EPC: 'Epc',
  DSA: 'Dsa',
  aboutUs: 'Aboutus',
  estimator: 'Estimator',
  mySavings: 'MySavings',
  residentialLoan: 'loan-products/residential',
  knowledge: 'knowledge',
  solfinPrivacyPolicy: 'Privacypolicy',
  dhanvikasPrivacyPolicy: 'DhanvikasPrivacypolicy',
  refundPolicy: 'Refundpolicy',
  dhanvikasFairPracticeCodePolicy: 'FairPracticeCode',
  solfinGrievanceRedressalPolicy: 'GrievanceRedressalPolicy',
  termsAndConditions: 'Termsandconditions',
  distributorJoin: 'distributor/join',
  registrationComplete: 'RegistrationComplete',
  custRegistrationComplete: 'cust-registration-complete',
  custRegistered: 'cust-registered',
  partnerRegistrationComplete: 'PartnerRegistrationComplete',
  lease: 'lease',
  comingSoon: 'coming-soon',
  mission: 'mission',
  contactSales: 'contact-sales',
  leaseContactSales: 'lease-contact-sales',
  admin: {
    leadsEstimator: '/estimator-leads',
    leadsApplication: '/application',
    leadsApplicationDetail: '/application/:id/details',
    leadsEpcReferred: '/epc-referred-leads',
    leadsEpcReferredDetails: '/epc-referred-leads/detail/:leadCode',
    customers: '/customers',
    customersDetails: '/customers/details/:id',
    loans: '/loans',
    loansDetails: '/loans/details/:loanCode',
    epcApplicants: '/epc-applicants',
    epcApplicantsDetails: '/epc-applicants/epc-applicants-detail/:id',
    epcEmpanelled: '/epc-empanelled',
    epcEmpanelledDetail: '/epc-empanelled/detail/:id',
    distributorLeads: '/distributor-leads',
    distributorKyc: '/distributor/kyc',
    distributorKycDetail: '/distributor-applicants/kyc-detail/:id',
    createEmpanelledForm: '/distributor-applicants/dynamic-form/:userCode',
    distributorCreditRating: '/distributor/credit-rating',
    distributorEmpanelled: '/distributor-empanelled',
    distributorEmpanelledApplicationDetails:
      '/distributor-applicants/credit-check/:id',
    distributorRejected: '/distributor-rejected',
    dsaApplicants: '/dsa-applicants',
    dsaApplicantsDetail: '/dsa-applicants/detail/:id',
    dsaEmpanelled: '/dsa-empanelled',
    dsaEmpanelledDetail: '/dsa-empanelled/detail/:id',
    invoicesPending: '/channelFinancing/invoices/pending',
    invoicesVerified: '/channelFinancing/invoices/verified',
    projects: '/projects',
    projectsDetail: '/project/detail/:id',
    userRole: '/user-role'
  },
  manufacturer: {
    dashboard: '/dashboard',
    comfortLettersDetails: '/comfort-letters/details/:letterCode',
    comfortLettersSigned: '/comfort-letters/signed',
    epcEmpanelled: '/epc-empanelled',
    epcLeads: '/epc-leads/:epcId'
  },
  NOTFound: '404',
  epc: {
    dashboard: '/dashboard',
    referredLead: '/referred-leads/:applicationCode',
    assignedLead: '/assigned-leads',
    addQuoteById: '/assigned-leads/add-quote/:id',
    viewSharedApplicationForm: '/assigned-leads/view-shared-form/:userCode',
    rateMatrix: '/rate-matrix',
    projects: '/projects',
    referredLeadDetails: '/referred-leads/:leadType/detail/:leadCode',
    projectDetailById: '/projects/:id/details',
    loans: '/loans',
    loanDetailById: '/loans/:id/details'
  },
  customer: {
    dashboard: '/home',
    notFound: '/404',
    applications: '/applications',
    applicationDetailsById: '/application/:id/details',
    newApplication: '/new-application',
    projects: '/projects',
    projectDetailById: '/project/:id/details',
    monitoring: '/monitoring',
    profile: '/profile',
    changePassword: '/change-password',
    registrationComplete: '/registration-complete',
    loans: '/loans',
    loanDetailById: '/loan/:id/details'
  },
  dsa: {
    dashboard: '/dashboard',
    myLeads: '/my-leads',
    projects: '/projects',
    leadsDetail: '/leads-detail/:id'
  },
  distributor: {
    dashboard: '/dashboard',
    pendingInvoices: '/pending-invoices',
    verifiedInvoices: '/verified-invoices',
    loanSummary: '/loan-summary',
    loans: '/loans',
    loanDetailById: '/loans/:id/details'
  }
};
