import axios from 'axios';

export const APIkey = process.env.REACT_APP_HOSTNAME;
export const APIkeyV2 = process.env.REACT_APP_HOSTNAME_v2;

export const submitQuery = async (newData) => {
  try {
    const { data } = await axios.post(`${APIkeyV2}/website/queries`, newData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return data;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        'An error occurred while submitting the query.'
    );
  }
};

export const registerEPC = async (payload) => {
  try {
    const { data } = await axios.post(`${APIkeyV2}/epc/onboard`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  } catch (err) {
    throw new Error(err.response?.data?.message);
  }
};

export const verifyPanOnBoarding = async (payload) => {
  try {
    const { data } = await axios.post(
      `${APIkeyV2}/epc/onboard/verify-pan`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return data;
  } catch (err) {
    throw new Error(err.response?.data?.message);
  }
};

export const getManufacturersList = async () => {
  try {
    const { data } = await axios.get(`${APIkeyV2}/manufacturers/list`, {
      withCredentials: true
    });
    return data;
  } catch (err) {
    throw new Error(err.response?.data?.message);
  }
};

export const logout = async () => {
  try {
    const data = await axios.get(`${APIkey}/user/logout`, {
      withCredentials: true
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const login = async (payload) => {
  try {
    const { data } = await axios.post(`${APIkey}/user/login`, payload, {
      withCredentials: true
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getCitiesByPincode = async (payload) => {
  try {
    const { data } = await axios.post(
      `${APIkey}/fields/filterCityByPincode`,
      payload,
      {
        withCredentials: true
      }
    );
    return data;
  } catch (err) {
    throw Error(err?.response?.data?.message);
  }
};

export const downloadApplicationDocumentAPI = async ({
  userRole = null,
  applicationCode = null,
  documentType = null
}) => {
  try {
    const { data } = await axios.get(
      `${APIkeyV2}/${userRole}/applications/${applicationCode}/documents/${documentType}`,
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const downloadProposalsDocumentAPI = async ({
  userRole = null,
  proposalId = null,
  documentType = null
}) => {
  try {
    const { data } = await axios.get(
      `${APIkeyV2}/${userRole}/applications/proposals/${proposalId}/documents/${documentType}`,
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getCustomerApplicationDetailsAPI = async (
  userCode = null,
  userTypeEnum = null
) => {
  try {
    const data = await axios.get(
      `${APIkeyV2}/loan-application/get-customer-application-details?userCode=${userCode}&userTypeEnum=${userTypeEnum}`,
      {
        withCredentials: true
      }
    );
    return data;
  } catch (err) {
    throw Error(err?.response?.data?.message || 'Oops something went wrong!');
  }
};

export const createApplicationAndSentToCustomerAPI = async (
  userCode = null,
  userTypeEnum = null,
  payload = {}
) => {
  try {
    const data = await axios.post(
      `${APIkeyV2}/loan-application/send-to-customer?userCode=${userCode}&userTypeEnum=${userTypeEnum}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return data;
  } catch (err) {
    throw Error(err?.response?.data?.message || 'Oops something went wrong!');
  }
};

export const getLoanApplicationDetailsAPI = async () => {
  try {
    const data = await axios.get(`${APIkeyV2}/loan-application`, {
      withCredentials: true
    });
    return data;
  } catch (err) {
    throw Error(err?.response?.data?.message || 'Oops something went wrong!');
  }
};


export const downloadProjectDocumentAPI = async ({
  userRole = null,
  projectCode = null,
  documentType = null
}) => {
  try {
    const { data } = await axios.get(
      `${APIkeyV2}/${userRole}/projects/${projectCode}/documents/${documentType}`,
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};