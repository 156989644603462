import { Navigate, useLocation } from 'react-router-dom';
import Loader from 'src/components/Loader';
import useAuthAndRedirect from 'src/Hooks/useAuth';

const ProtectedRoute = ({ Component }) => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuthAndRedirect(true);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return <Component />;
};

export default ProtectedRoute;
