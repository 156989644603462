import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  setCredentials,
  clearAuth,
  setLoading
} from '../store/slices/authSlice';
import AuthService from 'src/store/services/authService';
import { auth } from 'src/FrontEnd/dataService/DataService';
import { UI_ROUTES } from 'src/utils/routeConstants';

const useAuthAndRedirect = (
  isProtected = true,
  notFound = false,
  authUserNotAllowed = false
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, isLoading, user, isDistributor } = useSelector(
    (state) => state.auth
  );
  const { permissions } = useSelector((state) => state.permissions);

  const getAllowedRoutes = useCallback(
    (currentPermissions = []) => {
      const permissionToRoutesMap = {
        LEADS_VIEW: [
          'leadsEstimator',
          'leadsApplication',
          'leadsApplicationDetail',
          'leadsEpcReferred',
          'leadsEpcReferredDetails'
        ],
        CUSTOMERS_VIEW: ['customers', 'customersDetails'],
        EPC_VIEW: [
          'epcApplicants',
          'epcApplicantsDetails',
          'epcEmpanelled',
          'epcEmpanelledDetail'
        ],
        DISTRIBUTOR_VIEW: [
          'distributorLeads',
          'distributorKyc',
          'distributorKycDetail',
          'distributorCreditRating',
          'distributorEmpanelled',
          'distributorEmpanelledApplicationDetails',
          'distributorRejected'
        ],
        DSA_VIEW: [
          'dsaApplicants',
          'dsaApplicantsDetail',
          'dsaEmpanelled',
          'dsaEmpanelledDetail'
        ],
        INVOICES_VIEW: ['invoicesPending', 'invoicesVerified'],
        PROJECTS_VIEW: ['projects', 'projectsDetail'],
        LOAN_VIEW: ['loans', 'loansDetails']
      };

      const generateRoutesArray = (routes) =>
        currentPermissions
          .flatMap((permission) => permissionToRoutesMap[permission] || [])
          .map((route) => `/admin${routes[route]}`);

      return {
        ADMIN: Object.values(UI_ROUTES.admin).map((route) => `/admin${route}`),
        EPC: isDistributor
          ? Object.values(UI_ROUTES.distributor).map(
              (route) => `/distributor${route}`
            )
          : Object.values(UI_ROUTES.epc).map((route) => `/epc${route}`),
        CUSTOMER: Object.values(UI_ROUTES.customer).map(
          (route) => `/customer${route}`
        ),
        DSA: Object.values(UI_ROUTES.dsa).map((route) => `/dsa${route}`),
        MANUFACTURER_PARTNER: Object.values(UI_ROUTES.manufacturer).map(
          (route) => `/manufacturer${route}`
        ),
        INTERNAL_USER: generateRoutesArray(UI_ROUTES.admin)
      };
    },
    [isDistributor]
  );

  const handleRouteRedirect = useCallback(
    (currentUser) => {
      if (!currentUser) return;

      const allowedRoutes = getAllowedRoutes(permissions);
      const userTypeCode = currentUser?.otherUserData?.user_type_code;
      const userAllowedRoutes = allowedRoutes[userTypeCode] || [];
      const currentPath = location.pathname;

      const isRouteAllowed = userAllowedRoutes.some((route) =>
        route.includes(':')
          ? currentPath.startsWith(route.split(':')[0])
          : route === currentPath
      );

      if (!isRouteAllowed) {
        const defaultRoute =
          userAllowedRoutes.find((route) => route.includes('dashboard')) ||
          userAllowedRoutes[0];
        navigate(defaultRoute || '/', { replace: true });
      }
    },
    [navigate, location.pathname, permissions, getAllowedRoutes]
  );

  const redirectToLogin = useCallback(() => {
    dispatch(clearAuth());
    navigate('/login', { state: { from: location.pathname } });
  }, [dispatch, navigate, location.pathname]);

  useEffect(() => {
    if (isAuthenticated && user) {
      handleRouteRedirect(user);
    }
  }, [isAuthenticated, user, handleRouteRedirect]);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const storedAuth = AuthService.getAuth();
        if (!storedAuth?.token) {
          redirectToLogin();
          return;
        }

        const authResponse = await auth();
        if (authResponse === 400) {
          redirectToLogin();
          return;
        }

        dispatch(setCredentials(storedAuth));
      } catch (error) {
        console.error('Error during auth initialization:', error);
        redirectToLogin();
      }
    };

    if (notFound) {
      if (!isAuthenticated) {
        initializeAuth();
      }
    } else if (authUserNotAllowed) {
      initializeAuth();
    } else if (!isProtected || isAuthenticated) {
      dispatch(setLoading(false));
    } else {
      initializeAuth();
    }
  }, [dispatch, isProtected, isAuthenticated, redirectToLogin]);

  return { isAuthenticated, isLoading };
};

export default useAuthAndRedirect;
