import { useEffect } from 'react';

export const useZohoCleanup = () => {
  useEffect(() => {
    const removeZohoElements = () => {
      const script = document.getElementById('zohodeskasapscript');
      if (script?.parentNode) {
        script.parentNode.removeChild(script);
      }

      delete window.ZohoDeskAsapReady;
      delete window.ZohoDeskAsap__asyncalls;
      delete window.ZohoDeskAsapReadyStatus;
    };

    removeZohoElements();

    return () => removeZohoElements();
  }, []);
};
