import { useNavigate } from 'react-router-dom';

import { Box, Typography, Button, Container } from '@mui/material';
import useAuthAndRedirect from 'src/Hooks/useAuth';

const NotFound = ({ is404Error = false }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthAndRedirect(false, true);

  const handleGoBack = () => {
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  if (isAuthenticated && !is404Error) {
    return null;
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100%',
          maxHeight: '100vh',
          textAlign: 'center'
        }}
        className="py-5"
      >
        <Typography
          variant="h1"
          component="h1"
          className="display-1 fw-bold mb-4"
          color="primary"
        >
          404
        </Typography>

        <Typography variant="h4" component="h2" className="mb-4">
          Oops! Page Not Found
        </Typography>

        <Typography
          variant="body1"
          className="text-muted mb-4"
          sx={{ maxWidth: '600px' }}
        >
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </Typography>

        <Button
          variant="contained"
          size="large"
          onClick={handleGoBack}
          className="btn-lg shadow-sm"
        >
          Go Back Home
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
