import { createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/authService';

const initialState = {
  isAuthenticated: false,
  token: null,
  user: null,
  isLoading: true,
  isDistributor: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(state, action) {
      const { token, user } = action.payload;
      state.token = token;
      state.user = user;
      state.isAuthenticated = true;
      state.isLoading = false;
      AuthService.setAuth({ token, user });
    },
    clearAuth(state) {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.isDistributor = false;
      AuthService.clearAuth();
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDistributor(state, action) {
      state.isDistributor = action.payload;
    }
  }
});

export const { setCredentials, clearAuth, setLoading, setDistributor } =
  authSlice.actions;
export default authSlice.reducer;
