import { createTheme } from '@mui/material';

const FONT_PRIMARY = [
  'Public Sans',
  'Montserrat Alternates',
  'Montserrat',
  'sans-serif'
].join(',');

const FONT_SECONDARY = ['Public Sans', 'sans-serif'].join(',');

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          fontFamily: FONT_PRIMARY,
          backgroundColor: '#5e5e5ec9'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: FONT_SECONDARY,
          textTransform: 'capitalize',
          boxShadow: `none`,
          borderRadius: '1rem',
          fontWeight: 'bold',
          fontSize: '0.8rem',
          minWidth: 'auto',
          textWrap: 'nowrap'
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#161616',
            color: '#fff',
            fontFamily: FONT_SECONDARY
          }
        },
        containedInfo: {
          backgroundColor: '#161616',
          color: '#fff',
          fontFamily: FONT_SECONDARY,
          '&:hover': {
            backgroundColor: '#88F231',
            color: '#fff'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          borderLeft: '0.6rem #88F231 solid',
          fontFamily: 'Montserrat Alternates, sans-serif',
          paddingLeft: '0.5rem',
          color: 'text.primary',
          fontWeight: 700
        },
        subtitle1: {
          fontWeight: 600,
          fontSize: '1.15rem',
          lineHeight: 1.2,
          fontFamily: '"Montserrat Alternates", sans-serif',
          color: 'text.secondary'
        },
        body1: {
          fontWeight: 'bold',
          lineHeight: 1.2,
          fontFamily: '"Montserrat Alternates", sans-serif',
          color: 'text.primary'
        },
        normal: {
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: 1.5,
          lineHeight: 1.2,
          fontFamily: '"Montserrat Alternates", sans-serif'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          '&:hover fieldset': {
            borderColor: '#88F231'
          },
          '&.Mui-focused fieldset': {
            borderColor: '#88F231'
          },
          '&.Mui-error fieldset': {
            borderColor: '#dc3545'
          },
          '& fieldset': {
            borderColor: '#f0f0f0'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#88F231'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#88F231'
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#88F231'
          }
        },
        input: {
          '&:-webkit-autofill': {
            appearance: 'none',
            WebkitBackgroundClip: 'text',
            WebkitBoxShadow: '0 0 0 100px transparent inset',
            backgroundImage: 'none !important',
            backgroundColor:
              'light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important',
            color: 'fieldtext !important'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'text.primary'
          },
          '&.Mui-error': {
            color: 'text.primary'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '5px'
        },
        outlined: {
          borderRadius: '5px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontFamily: FONT_SECONDARY,
          fontWeight: 600,
          borderRadius: '8px',
          height: '24px',
          '& .MuiChip-label': {
            paddingLeft: '10px',
            paddingRight: '10px'
          },
          '& .MuiChip-icon': {
            fontSize: '1rem',
            marginLeft: '6px'
          },
          '& .MuiChip-deleteIcon': {
            fontSize: '1rem',
            marginRight: '6px',
            '&:hover': {
              color: 'inherit',
              opacity: 0.7
            }
          }
        },
        filled: {
          '&.MuiChip-colorPrimary': {
            backgroundColor: '#88F231',
            color: '#fff'
          },
          '&.MuiChip-colorSecondary': {
            backgroundColor: '#161616',
            color: '#fff'
          }
        },
        outlined: {
          borderWidth: '1.5px',
          '&.MuiChip-colorPrimary': {
            borderColor: '#88F231',
            color: '#88F231',
            '&:hover': {
              backgroundColor: 'rgba(136, 242, 49, 0.04)'
            }
          },
          '&.MuiChip-colorSecondary': {
            borderColor: '#161616',
            color: '#161616',
            '&:hover': {
              backgroundColor: 'rgba(22, 22, 22, 0.04)'
            }
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'text.primary !important',
            fontWeight: 'bold !important'
          },
          '&:hover': {
            color: '#88F231 !important'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#88F231 !important'
        }
      }
    }
  },
  typography: {
    fontFamily: FONT_PRIMARY
  },
  palette: {
    primary: {
      main: '#88F231',
      light: '#a4ff4d',
      dark: '#6bc922',
      contrastText: '#fff'
    },
    text: {
      primary: '#161616',
      secondary: '#28282885',
      disabled: '#6c757d',
      hint: '#495057'
    },
    background: {
      default: '#ffffff',
      paper: '#f8f9fa'
    },
    error: {
      main: '#dc3545',
      light: '#e35d6a',
      dark: '#b02a37'
    },
    success: {
      main: '#88F231',
      light: '#a4ff4d',
      dark: '#6bc922'
    },
    info: {
      main: '#f1c40f',
      light: '#f4d03f',
      dark: '#c29d0b'
    }
  }
});

export default theme;
