import { cookie } from 'src/utils/cookie';
import { storage } from 'src/utils/localStorage';

class AuthService {
  static TOKEN_KEY = 'token';
  static USER_KEY = 'user';

  static setAuth(data) {
    if (!data) return;

    const { token, user } = data;

    if (token) {
      storage.set(this.TOKEN_KEY, token);
    }

    if (user) {
      storage.set(this.USER_KEY, user);
    }
  }

  static getAuth() {
    try {
      const token = storage.get(this.TOKEN_KEY);
      const user = storage.get(this.USER_KEY);

      return {
        token,
        user,
        isAuthenticated: !!(token && user)
      };
    } catch (error) {
      console.error('Error getting auth:', error);
      return {
        token: null,
        user: null,
        isAuthenticated: false
      };
    }
  }

  static clearAuth() {
    cookie.erase(this.TOKEN_KEY);
    storage.remove(this.TOKEN_KEY);
    storage.remove(this.USER_KEY);
  }

  static isAuthenticated() {
    const { isAuthenticated } = this.getAuth();
    return isAuthenticated;
  }
}

export default AuthService;
