import { lazy, Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';

import AllRoutes from './FrontEnd/routes/AllRoutes';
import ProtectedRoutes from './FrontEnd/routes/ProtectedRoutes';
import Loader from './components/Loader';
import './App.css';
import NotFound from './FrontEnd/pages/404/404';

const AdminRoutes = lazy(() => import('./AdminDashboard/routes/AdminRoutes'));
const EpcRoutes = lazy(() => import('./EpcDashboard/routes/EpcRoutes'));
const CustomerRoutes = lazy(
  () => import('./CustomerDashboard/routes/CustomerRoutes')
);
const ManufacturerRoutes = lazy(
  () => import('./ManufacturerDashboard/routes/ManufacturerRoutes')
);
const DsaRoutes = lazy(() => import('./DsaDashboard/routes/DsaRoutes'));
const DistributorRoutes = lazy(
  () => import('./EpcDashboard/routes/DistributorRoutes')
);

const App = () => {
  useEffect(() => {
    // remove all the console logs from production
    if (process.env.NODE_ENV === 'production') {
      console.log = () => {};
    }
  }, []);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={'/*'} element={<AllRoutes />} />
          <Route
            path={'/epc/*'}
            element={<ProtectedRoutes Component={EpcRoutes} />}
          />
          <Route
            path={'/distributor/*'}
            element={<ProtectedRoutes Component={DistributorRoutes} />}
          />
          <Route
            path={'/admin/*'}
            element={<ProtectedRoutes Component={AdminRoutes} />}
          />
          <Route
            path={'/manufacturer/*'}
            element={<ProtectedRoutes Component={ManufacturerRoutes} />}
          />
          <Route
            path={'/customer/*'}
            element={<ProtectedRoutes Component={CustomerRoutes} />}
          />
          <Route
            path={'/dsa/*'}
            element={<ProtectedRoutes Component={DsaRoutes} />}
          />
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </>
  );
};

export default App;
