import { configureStore } from '@reduxjs/toolkit';
import tempReducer from './slices/tempSlice';
import permissionsReducer from './slices/permissionsSlice';
import authReducer from './slices/authSlice';
import { createAuthMiddleware } from './middleware/authMiddleware';

export const store = configureStore({
  reducer: {
    temp: tempReducer,
    permissions: permissionsReducer,
    auth: authReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(createAuthMiddleware())
});
