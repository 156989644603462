import PropTypes from 'prop-types';
import { Modal, Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from './CustomButton';

export const style = {
  backdrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'min(600px, 90vw)',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    borderRadius: 2,
    margin: '20px'
  },
  header: {
    background: '#000',
    color: '#fff',
    padding: '0.75rem 1rem',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '56px'
  },
  body: {
    flex: 1,
    overflow: 'auto',
    padding: '1rem',
    minHeight: '100px',
    maxHeight: 'calc(90vh - 120px)' // Subtract header and footer height
  },
  footer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '1rem',
    minHeight: '64px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
};

export const Footer = ({
  handleSubmit,
  handleClose,
  submitButtonLabel = 'Proceed',
  closeButtonLabel = 'Close',
  footerStyle = {},
  isSubmitButtonDisabled = false
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '1rem',
        width: '100%',
        ...footerStyle
      }}
    >
      <CustomButton onClick={handleClose} className="filter-btn">
        {closeButtonLabel}
      </CustomButton>
      <CustomButton onClick={handleSubmit} disabled={isSubmitButtonDisabled}>
        {submitButtonLabel}
      </CustomButton>
    </Box>
  );
};

const CustomModal = ({
  open,
  handleClose,
  headerComponent,
  headerLabel,
  children,
  footerComponent,
  modalMinHeight = '300px', // Changed default min height
  hasFooter = true
}) => {
  return (
    <Modal open={open} onClose={handleClose} sx={style.backdrop}>
      <Box
        sx={{
          ...style.modal,
          minHeight: modalMinHeight
        }}
      >
        <Box sx={style.header}>
          {headerComponent ? (
            headerComponent
          ) : (
            <Typography
              variant="h6"
              component="h2"
              sx={{
                color: '#fff',
                fontSize: '1.125rem',
                fontWeight: 500
              }}
            >
              {headerLabel}
            </Typography>
          )}
          <CloseIcon
            onClick={handleClose}
            sx={{
              cursor: 'pointer',
              transition: 'color 0.2s',
              '&:hover': {
                color: '#88F231'
              }
            }}
          />
        </Box>
        <Box sx={style.body}>{children}</Box>
        {hasFooter && (
          <Box sx={style.footer}>
            {footerComponent ? (
              footerComponent
            ) : (
              <CustomButton onClick={handleClose}>Close</CustomButton>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  headerComponent: PropTypes.element,
  headerLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  footerComponent: PropTypes.element,
  modalMinHeight: PropTypes.string,
  hasFooter: PropTypes.bool
};

CustomModal.defaultProps = {
  headerComponent: null,
  headerLabel: '',
  footerComponent: null,
  modalMinHeight: '300px',
  hasFooter: true
};

export default CustomModal;
