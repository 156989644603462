function setItem(key, value) {
  try {
    const serializedValue = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error('Error setting item in localStorage:', error);
  }
}

function getItem(key) {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) return null;

    try {
      return JSON.parse(serializedValue);
    } catch {
      return serializedValue;
    }
  } catch (error) {
    console.error('Error getting item from localStorage:', error);
    return null;
  }
}

function removeItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing item from localStorage:', error);
  }
}

function clearStorage() {
  try {
    localStorage.clear();
  } catch (error) {
    console.error('Error clearing localStorage:', error);
  }
}

export const storage = {
  set: setItem,
  get: getItem,
  remove: removeItem,
  clear: clearStorage,
};
